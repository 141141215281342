/* ==========================================================================
   CALL ACTION SECTION
========================================================================== */

.call-to-action__container{
    grid-template-columns: repeat(1, 100%);
    justify-content: center;
    column-gap: 1.8rem;
}
.call-to-action {
    display: flex;
    justify-content: space-around;
}

.call-to-action h6 {
    font-size: var(--h2-font-size);
    text-align: center;
    padding-top:var(--mb-1);
    font-weight: var(--font-semi-bold);
    letter-spacing: 1px;
    margin-right: 8rem;

}

.call-to-action .button {
    float: right;
    margin-left: 8rem;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .call-to-action h6 {
        font-size: 20px;       
        margin-right: 4rem;
    
    }
    .call-to-action .button {        
        margin-left: 4rem;
    }
}
/* For medium devices */
@media screen and (max-width: 768px) {  
    .call-to-action {
        display: flex;
        padding: 50px 0;    
        flex-direction: column;
        align-items: center;        
    }
    .call-to-action h6 {   
        margin-bottom: var(--mb-1-5);  
        margin-right: 0rem;
    
    }
    .call-to-action .button {        
        margin-left: 0rem;
    }
}