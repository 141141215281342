/* ==========================================================================
   STAGES SECTION CSS
========================================================================== */

.stages__container{
    grid-template-columns: repeat(4, 180px);
    justify-content: space-between;
    column-gap: 2.8rem;    
}

.how-i-work-item {
    z-index: 1;
    text-align: center;
    position: relative;
    margin-bottom: 0px;
    margin-top: 60px;
}

.how-i-work-item svg {
    top: -22rem;
    right: -138px;
    width: 11.3rem;
    position: absolute;
    transform: skewY(-9deg);
}

.how-i-work-item .number {
    left: 50%;
    top: -30px;
    text-align: center;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.how-i-work-item .number span {
    width: 80px;
    height: 80px;
    font-size: 32px;
    font-weight: 700;
    position: relative;
    line-height: 80px;
    border-radius: 50%;
    color: var(--button-TextColor);
    display: inline-block;
    font-family: "Poppins", sans-serif;
    background: var(--stage-color);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.how-i-work-item .number-border {
    left: 50%;
    top: 10px;
    z-index: -3;
    position: absolute;
    margin-left: 30px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.how-i-work-item .number-border:after,
.how-i-work-item .number-border:before {
    top: 0;
    content: "";
    position: absolute;
    border-radius: 50%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 2px solid var(--stage-color);
}

.how-i-work-item .number-border:after {
    right: -20px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.how-i-work-item .number-border:before {
    right: -30px;
    width: 120px;
    height: 120px;
    line-height: 120px;
}

.how-i-work-item .img {
    margin-bottom: 50px;
    border-radius: 50%;
}

.how-i-work-item .img img {
    border-radius: 50%;
    /* width: 300px;
    height: 200px; */
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border: 20px solid var(--stage-color);
}

.how-i-work-item .text {
    padding: 22px;
    text-align: center;
    position: relative;
    border-radius: 80px;
    background: var(--stage-color);
    color: var(--button-TextColor);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.how-i-work-item .text:after {
    top: -58px;
    left: 50%;
    content: "";
    position: absolute;
    border: 30px solid;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-color: transparent transparent var(--stage-color) transparent;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    color: var(--button-TextColor);
}

.how-i-work-item .text h5 {
    color:  var(--stage-text-color);
}

.how-i-work-item:hover .number span {
    background:  var(--title-color-dark);
}

.how-i-work-item:hover .img img {
    border-color: var(--title-color-dark);
}

.how-i-work-item:hover .text {
    background: var(--title-color-dark);
}

.how-i-work-item:hover .text:after {
    border-color: transparent transparent var(--title-color-dark) transparent;
}

.how-i-work-item:hover .number-border:after,
.how-i-work-item:hover .number-border:before {
    border-color: var(--title-color-dark);
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .stages__container {
        grid-template-columns: repeat(4, 182px);
        justify-content: center;
        column-gap: 0.8rem;
    }
    .how-i-work-item .arrow-dashed-img {
        top: -82px;
        right: -62px;
        width: 60%;
        position: absolute;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) { 
    .stages__container {
        grid-template-columns: repeat(2, 192px);
        justify-content: space-evenly;
        column-gap: 1.8rem;
    }    
    .how-i-work-item svg {       
        display: none;
    }
    .how-i-work-item .img img{
        width: 200px;
    }
}

@media screen and (max-width: 576px) {  
    .stages__container {
        grid-template-columns: repeat(1, 340px);
        justify-content: center;
        column-gap: 1.2rem;
    }    
    .how-i-work-item .arrow-dashed-img {
      display: none;
    }
    .how-i-work-item .img img{
        width: 254px;
    }
    .how-i-work-item .text {
        padding: 24px;
    }
    .how-i-work-item .number span{
        width: 100px;
        height: 100px;
        line-height: 100px;
    }    
    .how-i-work-item svg {       
        display: none;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .stages__container {
        grid-template-columns: repeat(1, 240px);
        justify-content: center;
        column-gap: 1.2rem;
    }
   
    .how-i-work-item .img img{
        width: 200px;
    }
    .how-i-work-item .text h5 {
        color: var(--icon-color);
        font-size: 15px;
    }
    .how-i-work-item .text {
        padding: 14px;
    }
    .how-i-work-item .number span {
        width: 70px;
        height: 72px;
        font-size: 25px;
        font-weight: 700;
        position: relative;
        line-height: 74px;
        border-radius: 50%;
        color: var(--icon-color);
        display: inline-block;
        font-family: "Poppins", sans-serif;
        background: var(--stage-color);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
}