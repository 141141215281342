/* ==========================================================================
   PORTFOLIOS SECTION CSS
========================================================================== */

.portfolios__container{
    grid-template-columns: repeat(3, 270px);
    justify-content: center;
    column-gap: 1.8rem;
}

.portfolios__modal{
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: var(--z-modal);
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0 1rem;
   opacity: 0;
   visibility: hidden;
   transition: .3s;
}

.portfolios__modal-data{
    display: grid;
    grid-template-columns: repeat(3, 150px);
    justify-content: space-around;
    column-gap: 0.8rem;
    text-align: center;
    margin-bottom: -1px;
    background: var(--title-color);
    color: var(--body-color);
    border-bottom: 7px solid var(--title-color-dark);
    padding: var(--mb-0-25);
    border-radius: 15px 15px 0px 0px;
    font-style: italic;
    text-transform: uppercase;    
    font-weight: var(--font-medium);
}

.portfolios__modal-data i{
    text-transform: uppercase;
    cursor: pointer;
    font-style: italic;
    font-weight: 600;
    margin-top: 2px;
}

.portfolios__modal-content{
    width: 800px;
    position: relative;
    background-color: var(--container-color);
    padding: 1rem 2.5rem 1rem 2.5rem;
    border-radius: 1rem;
    text-align: center;
}

.portfolios__modal-content img{
    width: 100%;
    height: 450px;
    border-radius: 0px 0px 4px 4px;
    margin-bottom: var(--mb-0-25);
}

.portfolios__modal_close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color:var(--title-color);
    cursor: pointer;
    background-color: var(--body-color);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    box-shadow: 0px 0px 36px var(--container-color), 0px -5px 35px var(--button-color);
}

.portfolios__modal-title,
.portfolios__modal-description{
    text-align: center;
}

.portfolios__modal-title{
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    margin-bottom: var(--mb-1);
}

.portfolios__modal-description{
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.portfolios__modal-portfolios{
    row-gap: 0.75rem;
}

.portfolios__modal-portfolio{
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

.portfolios__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}

.portfolios__modal-info{
    font-size: var(--small-font-size);
}

/* Active Modal */
.active-modal{
    opacity: 1;
    visibility: visible;
}


.portfolio_single_item {
    margin-bottom: 30px;
    text-align: center;
    overflow: hidden;
}

#img-fluid{
    transform: scale(1);
    transition: 0.3s ease-in-out;
}
#img-fluid:hover{
    transform: scale(1.2);
}

.portfolio_info {
    background-color: var(--container-color);
    padding: 20px;
    padding-left: 40px;
    margin-left: 22px;
    text-align: left;
    position: relative;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.portfolio_info span {
    font-size: 24px;
    color:  var(--icon-color);
    position: absolute;
    height: 44px;
    width: 44px;
    background: var(--title-color-dark);
    line-height: 44px;
    text-align: center;
    left: -22px;
    top: 50%;
    margin-top: -22px;
    z-index: 1;
}

.portfolio_info span:hover {
    color: var(--title-color-dark);
}

.portfolio_info span::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0;
    background-color: var(--container-color);
    top: 0;
    right: 0;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    z-index: -1;
}

.portfolio_info span:Hover::after {
    right: auto;
    left: 0;
    width: 100%;
}

.portfolio_info h6 {
    font-weight: 600;
    font-size: 17px;
    color: var(--title-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.portfolio_info h6:hover {
    color: var(--title-color-dark);
}

.portfolio_info p {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
    position: relative;
    padding-left: 30px;
    color: var(--title-color);
}

.portfolio_info p::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.portfolio_info p:Hover::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color-dark);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

/*================ BREAKPOINT ================*/ 
@media screen and (max-width: 1200px) {
    .portfolios__container{
        grid-template-columns: repeat(3, 250px);
        justify-content: center;
        column-gap: 1.8rem;
    }
    .portfolio_info p{
        font-size: 11px;
    }
}

/* For large devices */
@media screen and (max-width: 992px) {
    .portfolios__container{
        grid-template-columns: repeat(3, 218px);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .portfolios__container{
        grid-template-columns: repeat(1, 1fr);
    }
    .portfolios__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
    .portfolios__icon{
        font-size: 1.5rem;
    }   
}

@media screen and (max-width: 576px) {  
    .portfolios__modal-data{      
        grid-template-columns: repeat(1, 150px);
        
    }
    .portfolios__container{
        grid-template-columns: repeat(1, 22rem);
    }

    .portfolios__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }

    .portfolios__modal-content{
        padding: 4.5rem 1.5rem 2.5rem;
    }

    .portfolios__modal-title{
        font-size: var(--h3-font-size);
    }
    .portfolios__modal-description{
        padding: 0;
    }
    .portfolios__modal-content img{
        width: 100%;
        height: 260px;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .portfolios__container{
        grid-template-columns: repeat(1, 17rem);
    }
    .portfolios__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
    .portfolios__modal-content img{
        width: 100%;
        height: 190px;
    }
    .portfolios__modal-title{
        font-size: var(--h2-font-size);
    }
}

@media screen and (max-width: 280px) {  
    .portfolios__container{
        grid-template-columns: repeat(1, 16rem);
    }    
}