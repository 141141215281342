/* ==========================================================================
   BLOG SECTION
========================================================================== */

.blogs__container{
    grid-template-columns: repeat(3, 270px);
    column-gap: 1.8rem;
}

.blogs__content{
    margin-bottom: var(--mb-1-5);
}

.blog_single_item {
    margin-bottom: 30px;
    text-align: center;
    overflow: hidden;
}

#img-fluid{
    transform: scale(1);
    transition: 0.3s ease-in-out;
}

#img-fluid:hover{
    transform: scale(1.2);
}

.blog_info {
    background-color: var(--container-color);
    padding: 20px;
    padding-left: 40px;
    margin-left: 22px;
    text-align: left;
    position: relative;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.blog_info span {
    font-size: 24px;
    color:  var(--icon-color);
    position: absolute;
    height: 44px;
    width: 44px;
    background: var(--title-color-dark);
    line-height: 44px;
    text-align: center;
    left: -22px;
    top: 50%;
    margin-top: -22px;
    z-index: 1;
}

.blog_info span:hover {
    color: var(--title-color-dark);
}

.blog_info span::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0;
    background-color: var(--container-color);
    top: 0;
    right: 0;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    z-index: -1;
}

.blog_info span:Hover::after {
    right: auto;
    left: 0;
    width: 100%;
}

.blog_info h6 {
    font-weight: 600;
    font-size: 17px;
    color: var(--title-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.blog_info h6:hover {
    color: var(--title-color-dark);
}

.blog_info p {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
    position: relative;
    padding-left: 30px;
    color: var(--title-color);
}

.portfolio_info p::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.blog_info p::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}


.blog_info p:Hover::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color-dark);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}


.swiper-horizontal>.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{
    width: 22px;
    height: 12px;
    border-radius: 2px;
}

/*================ BREAKPOINT ================*/ 
@media screen and (max-width: 1200px) {
    .blog_info p{
        font-size: 11px;
    }
}

/* For large devices */
@media screen and (max-width: 992px) {
    .blogs__container{
        grid-template-columns: repeat(3, 218px);        
    }
    .blogs__content{
        padding: 0rem 1rem 0rem 1rem;
    }  
}

/* For medium devices */
@media screen and (max-width: 780px) {  
    .blogs__container{
        grid-template-columns: repeat(2, 1fr);
    }

    .blogs__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }    
}

@media screen and (max-width: 576px) {  
    .blogs__container{
        grid-template-columns: repeat(1, 22rem);
    }

    .blogs__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .blogs__container{
        grid-template-columns: repeat(1, 18rem);
    }
    .blogs__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
}

@media screen and (max-width: 280px) {  
    .blogs__container{
        grid-template-columns: repeat(1, 16rem);
    }   
}