/* ==========================================================================
   SCROLL UP CSS
========================================================================== */

.scrollup{
    position: fixed;
    right: 2.5rem;
    bottom: -20%;    
    background-color: var(--button-color);    
    color: var(--icon-color);
    opacity: 0.8;
    padding: 0.0rem 0.4rem;
    border-radius: 0.4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
    border: 3px solid transparent;
}

.scrollup__icon{
    margin-top: 5px;
    font-size: 2rem;
}

.scrollup:hover{
    background-color: var(--button-hover-color);
    color: var(--icon-color);
    border: 3px solid var(--text-color);
}

/* Show Scroll */
.show-scroll{
    bottom: 3rem;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .scrollup{
        right: 1.5rem;
        padding: 0.0rem 0.4rem;
    }
    .scrollup__icon{
        font-size: 1.25rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .show-scroll{
        bottom: 4rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .scrollup{
        right: 1rem;
    }
}