@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
/*================ GOOGLE FONTS ================*/

/*================ VARIABLES CSS ================*/
:root{
--header-height:3rem; 

/* Font and typography */
--body-font:"Poppins", sans-serif;

/*.5rem = 8px | 1rem = 16px ...*/
--big-font-size: 3.3rem;
--m-font-size: 2.6rem;
--h1-font-size: 2.25rem;
--h2-font-size: 1.5rem;
--h3-font-size: 1.25rem;
--normal-font-size :1rem;
--small-font-size: 0.875rem;
--smaller-font-size: 0.813rem;
--tiny-font-size: 0.625rem;

/* Font Weight */
--font-normal:400;
--font-medium: 500;
--font-semi-bold:600;
--font-large:900;

/* Margins Bottom */
/*.5rem 8px | 1rem = 16px ...*/
--mb-0-25:0.25rem;
--mb-0-5:0.5rem;
--mb-0-75:0.75rem;
--mb-1:1rem;
--mb-1-5:1.5rem;
--mb-2:2rem;
--mb-2-5:2.5rem;
--mb-3:3rem;

/* Z Index */     
--z-tooltip:10;
--z-fixed:100;
--z-modal:1000;
}

/*================ Responsive typography ================*/ 
@media screen and(max-width:992px){
  :root{ 
    --big-font-size:2.75rem;
    --h1-font-size:1.5rem;
    --h2-font-size:1.25rem;
    --h3-font-size:1rem;
    --normal-font-size:0.938rem;
    --small-font-size:0.813rem;
    --smaller-font-size:0.75rem;
  }
}

/*================ BASE ================*/ 
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

.landing-theme{
  --hue:0;
  --sat:0%;
  --title-color:hsl(var(--hue),var(--sat),20%);
  --title-color-dark:hsl(var(--hue),var(--sat),0%);
  --text-color:hsl(var(--hue),var(--sat),46%);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:hsl(var(--hue),var(--sat),20%); 
  --border-color:#646464; 
  --button-color:#e21152; 
  --button-hover-color:#ce0b49; 
  --icon-color:#fff; 
  --icon-color-dark:#fff; 
}

/* Light Mode Color*/
 .light-theme{
  /* Color mode HSL(hue,saturation,lightness) */
  --hue:0;
  --sat:0%;
  --title-color:hsl(var(--hue),var(--sat),20%);
  --title-color-dark:hsl(var(--hue),var(--sat),0%);
  --text-color:hsl(var(--hue),var(--sat),46%);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:hsl(var(--hue),var(--sat),20%);
  --border-color:hsl(var(--hue),var(--sat),46%); 
  --button-color:hsl(var(--hue),var(--sat),20%); 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:rgb(164, 164, 164);
}

.light_blue-theme{
  --hue:0;
  --sat:0%;
  --title-color:rgb(46, 46, 46);
  --title-color-dark:#047afa;
  --text-color:rgb(45, 45, 45);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:rgb(30, 30, 30);
  --border-color:rgb(24, 24, 24); 
  --button-color:#007aff; 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:#007aff;
}

.light_yellow-theme{
  --hue:0;
  --sat:0%;
  --title-color:rgb(46, 46, 46);
  --title-color-dark:#ffb400;
  --text-color:rgb(45, 45, 45);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:rgb(30, 30, 30);
  --border-color:rgb(24, 24, 24); 
  --button-color:#ffb400; 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:#ffb400;
}

.light_orange-theme{
  --hue:0;
  --sat:0%;
  --title-color:rgb(46, 46, 46);
  --title-color-dark:#fb5b0f;
  --text-color:rgb(45, 45, 45);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:rgb(30, 30, 30);
  --border-color:rgb(24, 24, 24); 
  --button-color:#fb5b0f; 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:#fb5b0f;
}

.light_pink-theme{
  --hue:0;
  --sat:0%;
  --title-color:rgb(46, 46, 46);
  --title-color-dark:#e21152;
  --text-color:rgb(45, 45, 45);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:rgb(30, 30, 30);
  --border-color:rgb(24, 24, 24); 
  --button-color:#e21152; 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:#e21152;
}

/* Dark Mode Color*/
.dark-theme{
  --title-color:#dee1e2;
  --title-color-dark:#047afa;
  --text-color:#dee1e2;
  --body-color:#030303;
  --container-color:#111111; 
  --stage-color:#1b1b1b; 
  --border-color:#646464; 
  --button-color:#007aff; 
  --button-hover-color:#111111; 
  --icon-color:#dee1e2; 
  --icon-color-dark:#dee1e2; 
  --header-Border-color:#007aff;
}
  
.dark_yellow-theme{
  --title-color:#FFFFFF;
  --title-color-dark:#ffb400;
  --text-color:#FFFFFF;
  --body-color:#030303;
  --container-color:#111111; 
  --stage-color:#1b1b1b; 
  --border-color:#646464; 
  --button-color:#ffb400; 
  --button-hover-color:#111111; 
  --icon-color:#FFFFFF; 
  --icon-color-dark:#FFFFFF;
  --header-Border-color:#ffb400;
}

.dark_orange-theme{
  --title-color:#dee1e2;
  --title-color-dark:#fb5b0f;
  --text-color:#ffffff;
  --body-color:#030303;
  --container-color:#111111; 
  --stage-color:#1b1b1b; 
  --border-color:#646464; 
  --button-color:#fb5b0f; 
  --button-hover-color:#111111; 
  --icon-color:#dee1e2; 
  --icon-color-dark:#dee1e2; 
  --header-Border-color:#fb5b0f;
}

.dark_pink-theme{
  --title-color:#dee1e2;
  --title-color-dark:#e21152;
  --text-color:#ffffff;
  --body-color:#030303;
  --container-color:#111111; 
  --stage-color:#1b1b1b; 
  --border-color:#646464; 
  --button-color:#e21152; 
  --button-hover-color:#111111; 
  --icon-color:#dee1e2; 
  --icon-color-dark:#dee1e2;
  --header-Border-color:#e21152;
}  

body{
  background-color: var(--body-color);
  color: var(--text-color);
}

/* ScrollBar Width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--container-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--title-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--title-color-dark);
}

h1, h2, h3{
color: var(--text-color);
font-weight: 600;
font-weight: var(--font-semi-bold);
}

body,
button,
input,
textarea{
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 1rem;
  font-size: var(--normal-font-size);
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}

/*================ REUSABLE CSS CLASSES ================*/ 
.section{
  padding:6rem 0 2rem;
}

.section__title{
  font-size:1.5rem;
  font-size:var(--h2-font-size);
  color:var(--title-color-dark);  
  margin-bottom:0.5rem;
  text-transform: uppercase;
}

.section__subtitle{
  display:block;
  font-size:2.25rem;
  font-size:var(--h1-font-size);
  font-weight: 900;
  margin-bottom:-1.5rem;
  text-transform: uppercase;
}

.section__zigzag{
  display:block;
  font-size:var(--h6-font-size);
  font-weight: 900;
  margin-bottom:2rem;
  text-transform: uppercase;
}

.section__title,
.section__subtitle,
.section__zigzag{
  text-align: center;
}

.section__mainTitle {
  font-size: 70px;
  text-transform: uppercase;
  -webkit-text-stroke: 2.8px var(--container-color);
  display:block;
  letter-spacing: 8px;
  text-align: center;
  margin-bottom: -4.1rem;
  color: var(--body-color);
  text-shadow: 2px 8px 6px var(--text-color),
                 0px -5px 35px rgba(255,255,255,0.3);
}

/*================ LAYOUT ================*/ 
.container{
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
}

.grid{
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

/*================ BUTTONS ================*/
.button{
  display: inline-block;
  position:relative;
  background-color: var(--button-color);
  color: var(--icon-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  transition: color 1s;
  text-transform: uppercase;
  border: 3px solid transparent;
} 

.button:hover{
  background-color: var(--button-hover-color);
  color: var(--icon-color-dark);
  border: 3px solid var(--text-color);
} 

.button__icon{
  margin-left: 0.5rem;
  margin-left: var(--mb-0-5);
}

.button--flex{
  display:inline-flex;
  align-items: center;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
  .container{
    margin-left: 1.5rem;
    margin-left: var(--mb-1-5);
    margin-right: 1.5rem;
    margin-right: var(--mb-1-5);
  }
  .button{
    padding: 1rem 1.75rem;
  }
  .button__icon{
    width: 22px;
    height: 22px;
  }
  .section__title {
    font-size: 1.5rem;
    font-size: var(--h2-font-size);
    color: var(--title-color-dark);
    margin-bottom: 0.5rem;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }
  .section__mainTitle{
    display: none;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
  body{
    margin: 0 0 3rem 0;
    margin: 0 0 var(--header-height) 0;
  }
  .section {
      padding: 2rem 0 4rem;
  }
  .section__subtitle{
    margin-bottom: 0.5rem;
    margin-bottom: var(--mb-0-5);
  }
}
@media screen and (max-width: 576px) {  
  :root{
    --big-font-size:2.6rem;
  }
  .section__subtitle{
    font-size:1.5rem;
    font-size:var(--h2-font-size);
  }
}
/* For small devices */
@media screen and (max-width: 400px) {  
  :root{
    --big-font-size:2.25rem;
  }
  .container{
    margin-left: 1.5rem;
    margin-left: var(--mb-1-5);
    margin-right: 1.5rem;
    margin-right: var(--mb-1-5);
  }  
  .section__subtitle{
    font-size:var(--h6-font-size);
  }
}
/* ==========================================================================
   HEADER SECTION CSS
========================================================================== */

.header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav{
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}

.nav__logo,
.nav__toggle{
    color: var(--title-color);
    font-weight: var(--font-medium);    
    font-size: 30px;
    cursor: pointer;
}

.nav__menu{
    margin-right: -4.8rem;
    margin-bottom: -5px;
}

.nav__list{
    display: flex;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
}

.nav__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--normal-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    border-bottom: 3px solid transparent;
    transition: .3s;
}

.nav__icon,
.nav__close,
.nav__toggle{
    display: none;
}

/* Active Link */
.active,
.nav__link:hover {
    color: var(--title-color-dark);
    border-bottom: 3px solid var(--title-color-dark);
    padding-bottom: 1px;    
    cursor: pointer;
}

/* Change Background Header */
.scroll-header{
    box-shadow: 0 -4px 8px var(--header-Border-color);
    margin-top: -3px;
    padding-top: 3px;
    background-color: var(--container-color);
}

.HeaderBtn{
    padding: 0.8rem 1.8rem 0.8rem 1.8rem ;
    border-radius: 0.8rem;
    cursor: pointer;
}

a.button.HeaderBtn.active{
    /* background-color: var(--title-color-dark);    */
    color: var(--button-TextColor);
}

.nav__close{
    background-color: var(--body-color);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    box-shadow: 0px 0px 36px var(--text-color), 0px -5px 35px rgba(255,255,255,0.3);
    margin-bottom: 15px;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .nav__logo, .nav__toggle {
        font-size: 24px;
    }

    .nav__list {
        display: flex;
        grid-column-gap: 1rem;
        -webkit-column-gap: 1rem;
                column-gap: 1rem;
    }

    .nav__link{
        font-size: var(--small-font-size);
        border: none;
    }

    .HeaderBtn {
        padding: 0.8rem 1.6rem 0.8rem 1.6rem;
        border-radius: 0.8rem;
        font-size: 12px;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .header{
        top: initial;
        bottom: -1px;
        padding: 0.4rem 0rem 0.3rem 0rem;
        background-color: var(--container-color);
    }
    .scroll-header{
        box-shadow: 0 -2px 180px var(--header-Border-color);
    }
    .nav{
        height: var(--header-height);
    }    
    .nav__menu{
        position:fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--container-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }    
    .HeaderBtn{
        display: none;
    }
    .active-link, .nav__link:hover {
        color: var(--title-color-dark);
        border-bottom: 3px solid transparent;
        padding-bottom: 1px;
        border: none;
    }
    .VLine{
        display: none;
    }        
    /* Show Menu*/
    .show-menu{
        bottom: 0px;
    }
    .nav__list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        gap: 2rem;
    }
    .nav__icon{
        font-size: 1.2rem;
    }
    .nav__close{
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
        box-shadow: 0px 0px 6px var(--text-color), 0px -5px 35px rgba(255,255,255,0.3);
    }
    .nav__close:hover{
        color: var(--title-color-dark);
    }
    .nav__toggle{
        font-size: 1.1rem;
        cursor: pointer;
    }
    .nav__icon,
    .nav__close,
    .nav__toggle{
        display: block;
    }
}

@media screen and (max-width: 576px) {  
    .nav__logo {
        font-size: 18px;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {     
    .nav__menu{
        padding: 2rem 0.25rem 4rem;
    }
    .nav__list{
        grid-column-gap: 0;
        -webkit-column-gap: 0;
                column-gap: 0;
    }
}
/* ==========================================================================
   HOME SECTION CSS
========================================================================== */

.home__container{
    grid-row-gap: 7rem;
    row-gap: 7rem;
}

.home__content{
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    grid-column-gap: 1.2rem;
    -webkit-column-gap: 1.2rem;
            column-gap: 1.2rem;
    align-items: center;
}

.home__social{
    display: grid;
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.home__social-icon{
    font-size: 1.5rem;
    color: var(--title-color);
}

.home__social-icon:hover{
    font-size: 1.5rem;
    color: var(--title-color-dark);
}

.VLine {
    border-left: 2.6px solid var(--title-color);
    height: 280px;
    position: absolute;
    margin-left: 0.7rem;
    margin-top: 210px;
}

.wave {
    -webkit-animation-name: wave-animation;
            animation-name: wave-animation; 
    -webkit-animation-duration: 2.5s; 
            animation-duration: 2.5s;       
    -webkit-animation-iteration-count: infinite;       
            animation-iteration-count: infinite;  
    -webkit-transform-origin: 70% 70%;  
            transform-origin: 70% 70%;       
    display: inline-block;
}

@-webkit-keyframes wave-animation {
    0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
    10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }  
    20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
    30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
    40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
    50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
    60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }  
    100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}

@keyframes wave-animation {
    0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
    10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }  
    20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
    30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
    40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
    50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
    60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }  
    100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}

.home__title{
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
    letter-spacing: 0.1rem;
}

.home__MainTitle{
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-0-25);
    letter-spacing: 0.2rem;
}

.home__hand{
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}

.home__subtitle{
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__subtitle::before{
    content: "";
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--title-color);
    left: 0;
    top: 1rem;
}

.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.home__img{
    background: url(/static/media/profile.5d3462ab.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    -webkit-animation: profile_animate 8s ease-in-out infinite 1s;
            animation: profile_animate 8s ease-in-out infinite 1s;
    transition: all 0.5s ease-in;
}

@-webkit-keyframes profile_animate {
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

@keyframes profile_animate {
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.home__scroll{
    margin-left: 9.25rem;
}

.wheel{
    -webkit-animation: scroll 2 ease infinite;
            animation: scroll 2 ease infinite;
}

@-webkit-keyframes scroll{
    0%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    30%{
        -webkit-transform: translateY(3.75rem);
                transform: translateY(3.75rem);
    }
    0%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes scroll{
    0%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    30%{
        -webkit-transform: translateY(3.75rem);
                transform: translateY(3.75rem);
    }
    0%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.home__scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow{
    font-size: 1.25rem;
    color:var(--title-color)
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
   .home__content{
        grid-template-columns: 100px repeat(2, 1fr);
        grid-column-gap: 1.25rem;
        -webkit-column-gap: 1.25rem;
                column-gap: 1.25rem;
   }
   .home__hand{
        width: 26px;
        height: 26px;
   }
   .home__subtitle{
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
   }
   .home__subtitle::before{
        width: 42px;
        top: 0.8rem;
   }
   .home__description{
        max-width: initial;
        margin-bottom: var(--mb-2-5);
   }
   .home__img{
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
   }   
   .home__scroll{
    margin-left: 7.5rem;
   }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .home__content{
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }
    .home__img{
        order:initial;
        justify-self: initial;
    }
    .home__data{
        grid-column: 1/3;
    }
    .home__img{
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 300px;
        height: 300px;
    }
    .home__scroll{
        display: none;
    }    
}

@media screen and (max-width: 576px) {  
    .home__img{
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 250px;
        height: 250px;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .home__img{
        width: 180px;
        height: 180px;
    }
    .home__hand{
        width: 22px;
        height: 22px;
    }
}


/* ==========================================================================
   ABOUT SECTION
========================================================================== */

.about__container{
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
}

.about__img{
    width: 350px;
    border-radius: 1.5rem;
    justify-self: center;
}

.about__info{
    grid-template-columns: repeat(3, 140px);
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.about__box{
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon{
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom:var(--mb-0-5)
}

.about__title{
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle{
    font-size: var(--tiny-font-size);
}

.about__description{
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
   .about__container{
        grid-template-columns: 1fr;
        grid-row-gap: 2.5rem;
        row-gap: 2.5rem;
   }
   .about__img{
        width: 220px;
   }
   .about__box{
        padding: 0.75rem 0.5rem;
   }

   .about__data{
        text-align: center;
   }

   .about__info{
        justify-content: center;
   }
   .about__description{
    padding: 0 5rem;
    margin-bottom: 2rem;
   }
}
/* For medium devices */
@media screen and (max-width: 576px) {  
    .about__info{
        grid-template-columns: repeat(3, 1fr);
    }

    .about__description{
        padding: 0;
    }
}
/* For small devices */
@media screen and (max-width: 350px) {  
    .about__info{
        grid-template-columns: repeat(2, 1fr);
    }
}


/* ==========================================================================
   SKILLS SECTION CSS
========================================================================== */

.skills__container{
    grid-template-columns: repeat(2, 350px);
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
    justify-content: center;
}

.skills__content{
    background-color: var(--container-color);
    border:1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 3rem;
    border-radius: 1.25rem;
}

.skills__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align:center;
    margin-bottom: var(--mb-1-5);
}

.skills__box{
    display: flex;
    justify-content: center;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
            column-gap: 2.5rem;
}

.skills__group{
    display: grid;
    align-items: flex-start;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.skills__data{
    display: flex;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
}

.skills .bx-badge-check{
    font-size: 1rem;
    color: var(--title-color);
}

.skills__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
}

.skills__level{
    font-size: var(--tiny-font-size);
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
   .skills__container{
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    grid-row-gap: 2rem;
    row-gap: 2rem;
   }
}

/* For medium devices */
@media screen and (max-width: 576px) {  
    .skills__container{
        grid-template-columns: 1fr;
    }
    .skills__content{
        padding: 1.5rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .skills__box{
        grid-column-gap: 1.25rem;
        -webkit-column-gap: 1.25rem;
                column-gap: 1.25rem;
    }    
    .skills__name{
        font-size: var(--small-font-size);
    }
}
/* ==========================================================================
   SERVICES SECTION CSS
========================================================================== */

.services__container{
    grid-template-columns: repeat(3, 270px);
    justify-content: center;
    grid-column-gap: 1.8rem;
    -webkit-column-gap: 1.8rem;
            column-gap: 1.8rem;
}

.services__content{
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--container-color);
    padding: 6rem 0 2rem 2.5rem;
    transition: 0.5s;
}

.services__icon{
    display: block;
    font-size: 2rem;
    color:var(--title-color);
    margin-bottom: var(--mb-1);
}

.services__title{
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}

.services__button{
    color:var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.services__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.services__button:hover .services__button-icon{
    -webkit-transform: translateX(00.25rem);
            transform: translateX(00.25rem);
}

.services__content:hover{ 
    /* transform: translateY(-20px); */
    background-color: var(--button-color);
}

.services__content:hover .services__icon{ 
    color: var(--icon-color);
}

.services__content:hover .services__title{ 
    color: var(--icon-color);
}

.services__content:hover .services__button{ 
    color: var(--icon-color);
}

.services__modal{
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: var(--z-modal);
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0 1rem;
   opacity: 0;
   visibility: hidden;
   transition: .3s;
}

.services__modal-content{
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.services__modal_close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color:var(--title-color);
    cursor: pointer;
    background-color: var(--body-color);
    border-radius: 50%;
    width: 26px;
    text-align: center;
    box-shadow: 0px 0px 36px var(--text-color), 0px -5px 35px rgba(255,255,255,0.3);
}

.services__modal_close svg{
    width: 1.5rem;
    height: 1.5rem;
}

.services__modal-title,
.services__modal-description{
    text-align: center;
}

.services__modal-title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.services__modal-description{
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.services__modal-services{
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
}

.services__modal-service{
    display: flex;
    align-items: center;
    grid-column-gap: .5rem;
    -webkit-column-gap: .5rem;
            column-gap: .5rem;
}

.services__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}

.services__modal-info{
    font-size: var(--small-font-size);
}

/* Active Modal */
.active-modal{
    opacity: 1;
    visibility: visible;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .services__container{
        grid-template-columns: repeat(3, 218px);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .services__container{
        grid-template-columns: repeat(2, 1fr);
    }
    .services__content{
        padding: 3.5rem 0.5rem 1.25rem 1.5rem;
    }
    .services__icon{
        font-size: 1.5rem;
    }
}
@media screen and (max-width: 576px) { 
    .services__modal-content{
        padding: 4.5rem 1.5rem 2.5rem;
    }
    .services__modal-description{
        padding: 0;
    }
}

@media screen and (max-width: 420px) { 
    .services__container{
        grid-template-columns: repeat(1, 1fr);
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .services__container{
        grid-template-columns: -webkit-max-content;
        grid-template-columns: max-content;
    }
    .services__content{
        padding-right: 6rem;
    }
}
/* ==========================================================================
   QUALIFICATION SECTION CSS
========================================================================== */

.qualification__container{
    max-width: 768px;
}

.qualification__tabs{
    display: flex;
    justify-content: center;
    margin-bottom: var(--mb-2);
}

.qualification__button{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin: 0 var(--mb-1);
    cursor: pointer;
}

.qualification__button:hover{
    color: var(--title-color-dark);
}

.qualification__icon{
    font-size: 1.8rem;
    margin-right: var(--mb-0-25);
}

.qualification__active{
    color:var(--title-color-dark)
}

.qualification__section{
    display: grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}

.qualification__content{
    display: none;
}

.qualification__content-active{
    display: block;
}

.qualification__data{
    display: grid;
    grid-template-columns: 1fr -webkit-max-content 1fr;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
}

.qualification__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle{
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-0-5);
}

.qualification__calender{
    font-size: var(--small-font-size);
    background-color: var(--button-color);
    color: var(--button-TextColor);
    padding: 0.1rem 0.1rem 0.1rem 0.6rem;
    border-radius: 38px;
    width: 9rem;
    margin-bottom: var(--mb-1);   
}

.qualification__calender span{
    margin-left: 0.4rem;
}

.qualification__calender svg{
    width: var(--small-font-size);
    height: var(--small-font-size);
    padding-top: 0.1rem;
}

.qualification__rounder{
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
}

.qualification__line{
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    -webkit-transform: translate(6px, -7px);
            transform: translate(6px, -7px);
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
  .qualification__container{
    margin-left: auto;
    margin-right: auto;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .qualification__container{
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
    .qualification__data{
        grid-column-gap: 0.5rem;
        -webkit-column-gap: 0.5rem;
                column-gap: 0.5rem;
    }
}
@media screen and (max-width: 576px) {  
    .qualification__section{
        grid-template-columns: initial;
    }

    .qualification__button{
        margin: 0 var(--mb-0-75);
    }
}

@media screen and (max-width: 400px) {  
    .qualification__calender {
        background-color: transparent;
        color: var(--text-Color-dark);
        padding: 0px;
        width: auto;
        border-radius: 38px;
        margin-bottom: var(--mb-1);
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .qualification__data{
        grid-gap:0.5rem;
        gap:0.5rem;
    }
    .qualification__tabs{
        align-items: center;
        flex-direction: column;
    }
    
}

@media screen and (max-width: 290px) {  
    .qualification__title {
        font-size: var(--small-font-size);
    }
}


/* ==========================================================================
   PORTFOLIOS SECTION CSS
========================================================================== */

.portfolios__container{
    grid-template-columns: repeat(3, 270px);
    justify-content: center;
    grid-column-gap: 1.8rem;
    -webkit-column-gap: 1.8rem;
            column-gap: 1.8rem;
}

.portfolios__modal{
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: var(--z-modal);
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0 1rem;
   opacity: 0;
   visibility: hidden;
   transition: .3s;
}

.portfolios__modal-data{
    display: grid;
    grid-template-columns: repeat(3, 150px);
    justify-content: space-around;
    grid-column-gap: 0.8rem;
    -webkit-column-gap: 0.8rem;
            column-gap: 0.8rem;
    text-align: center;
    margin-bottom: -1px;
    background: var(--title-color);
    color: var(--body-color);
    border-bottom: 7px solid var(--title-color-dark);
    padding: var(--mb-0-25);
    border-radius: 15px 15px 0px 0px;
    font-style: italic;
    text-transform: uppercase;    
    font-weight: var(--font-medium);
}

.portfolios__modal-data i{
    text-transform: uppercase;
    cursor: pointer;
    font-style: italic;
    font-weight: 600;
    margin-top: 2px;
}

.portfolios__modal-content{
    width: 800px;
    position: relative;
    background-color: var(--container-color);
    padding: 1rem 2.5rem 1rem 2.5rem;
    border-radius: 1rem;
    text-align: center;
}

.portfolios__modal-content img{
    width: 100%;
    height: 450px;
    border-radius: 0px 0px 4px 4px;
    margin-bottom: var(--mb-0-25);
}

.portfolios__modal_close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color:var(--title-color);
    cursor: pointer;
    background-color: var(--body-color);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    box-shadow: 0px 0px 36px var(--container-color), 0px -5px 35px var(--button-color);
}

.portfolios__modal-title,
.portfolios__modal-description{
    text-align: center;
}

.portfolios__modal-title{
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    margin-bottom: var(--mb-1);
}

.portfolios__modal-description{
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.portfolios__modal-portfolios{
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
}

.portfolios__modal-portfolio{
    display: flex;
    align-items: center;
    grid-column-gap: .5rem;
    -webkit-column-gap: .5rem;
            column-gap: .5rem;
}

.portfolios__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}

.portfolios__modal-info{
    font-size: var(--small-font-size);
}

/* Active Modal */
.active-modal{
    opacity: 1;
    visibility: visible;
}


.portfolio_single_item {
    margin-bottom: 30px;
    text-align: center;
    overflow: hidden;
}

#img-fluid{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.3s ease-in-out;
}
#img-fluid:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.portfolio_info {
    background-color: var(--container-color);
    padding: 20px;
    padding-left: 40px;
    margin-left: 22px;
    text-align: left;
    position: relative;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.portfolio_info span {
    font-size: 24px;
    color:  var(--icon-color);
    position: absolute;
    height: 44px;
    width: 44px;
    background: var(--title-color-dark);
    line-height: 44px;
    text-align: center;
    left: -22px;
    top: 50%;
    margin-top: -22px;
    z-index: 1;
}

.portfolio_info span:hover {
    color: var(--title-color-dark);
}

.portfolio_info span::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0;
    background-color: var(--container-color);
    top: 0;
    right: 0;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    z-index: -1;
}

.portfolio_info span:Hover::after {
    right: auto;
    left: 0;
    width: 100%;
}

.portfolio_info h6 {
    font-weight: 600;
    font-size: 17px;
    color: var(--title-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.portfolio_info h6:hover {
    color: var(--title-color-dark);
}

.portfolio_info p {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
    position: relative;
    padding-left: 30px;
    color: var(--title-color);
}

.portfolio_info p::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.portfolio_info p:Hover::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color-dark);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

/*================ BREAKPOINT ================*/ 
@media screen and (max-width: 1200px) {
    .portfolios__container{
        grid-template-columns: repeat(3, 250px);
        justify-content: center;
        grid-column-gap: 1.8rem;
        -webkit-column-gap: 1.8rem;
                column-gap: 1.8rem;
    }
    .portfolio_info p{
        font-size: 11px;
    }
}

/* For large devices */
@media screen and (max-width: 992px) {
    .portfolios__container{
        grid-template-columns: repeat(3, 218px);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .portfolios__container{
        grid-template-columns: repeat(1, 1fr);
    }
    .portfolios__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
    .portfolios__icon{
        font-size: 1.5rem;
    }   
}

@media screen and (max-width: 576px) {  
    .portfolios__modal-data{      
        grid-template-columns: repeat(1, 150px);
        
    }
    .portfolios__container{
        grid-template-columns: repeat(1, 22rem);
    }

    .portfolios__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }

    .portfolios__modal-content{
        padding: 4.5rem 1.5rem 2.5rem;
    }

    .portfolios__modal-title{
        font-size: var(--h3-font-size);
    }
    .portfolios__modal-description{
        padding: 0;
    }
    .portfolios__modal-content img{
        width: 100%;
        height: 260px;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .portfolios__container{
        grid-template-columns: repeat(1, 17rem);
    }
    .portfolios__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
    .portfolios__modal-content img{
        width: 100%;
        height: 190px;
    }
    .portfolios__modal-title{
        font-size: var(--h2-font-size);
    }
}

@media screen and (max-width: 280px) {  
    .portfolios__container{
        grid-template-columns: repeat(1, 16rem);
    }    
}
/* ==========================================================================
   BLOG SECTION
========================================================================== */

.blogs__container{
    grid-template-columns: repeat(3, 270px);
    grid-column-gap: 1.8rem;
    -webkit-column-gap: 1.8rem;
            column-gap: 1.8rem;
}

.blogs__content{
    margin-bottom: var(--mb-1-5);
}

.blog_single_item {
    margin-bottom: 30px;
    text-align: center;
    overflow: hidden;
}

#img-fluid{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.3s ease-in-out;
}

#img-fluid:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.blog_info {
    background-color: var(--container-color);
    padding: 20px;
    padding-left: 40px;
    margin-left: 22px;
    text-align: left;
    position: relative;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.blog_info span {
    font-size: 24px;
    color:  var(--icon-color);
    position: absolute;
    height: 44px;
    width: 44px;
    background: var(--title-color-dark);
    line-height: 44px;
    text-align: center;
    left: -22px;
    top: 50%;
    margin-top: -22px;
    z-index: 1;
}

.blog_info span:hover {
    color: var(--title-color-dark);
}

.blog_info span::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0;
    background-color: var(--container-color);
    top: 0;
    right: 0;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    z-index: -1;
}

.blog_info span:Hover::after {
    right: auto;
    left: 0;
    width: 100%;
}

.blog_info h6 {
    font-weight: 600;
    font-size: 17px;
    color: var(--title-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.blog_info h6:hover {
    color: var(--title-color-dark);
}

.blog_info p {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
    position: relative;
    padding-left: 30px;
    color: var(--title-color);
}

.portfolio_info p::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.blog_info p::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}


.blog_info p:Hover::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    top: 49%;
    left: 0;
    background-color: var(--title-color-dark);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}


.swiper-horizontal>.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{
    width: 22px;
    height: 12px;
    border-radius: 2px;
}

/*================ BREAKPOINT ================*/ 
@media screen and (max-width: 1200px) {
    .blog_info p{
        font-size: 11px;
    }
}

/* For large devices */
@media screen and (max-width: 992px) {
    .blogs__container{
        grid-template-columns: repeat(3, 218px);        
    }
    .blogs__content{
        padding: 0rem 1rem 0rem 1rem;
    }  
}

/* For medium devices */
@media screen and (max-width: 780px) {  
    .blogs__container{
        grid-template-columns: repeat(2, 1fr);
    }

    .blogs__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }    
}

@media screen and (max-width: 576px) {  
    .blogs__container{
        grid-template-columns: repeat(1, 22rem);
    }

    .blogs__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .blogs__container{
        grid-template-columns: repeat(1, 18rem);
    }
    .blogs__content{
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    }
}

@media screen and (max-width: 280px) {  
    .blogs__container{
        grid-template-columns: repeat(1, 16rem);
    }   
}
/* ==========================================================================
   TESTIMONIAL SECTION CSS
========================================================================== */

.testimonial__container{
    width: 750px;
}

.testimonial__card{
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    margin-bottom: var(--mb-3);
}

.testimonial__img{
    width: 60px;
    border-radius: 3rem;
    margin-bottom: var(--mb-1);
}

.testimonial__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25);
}

.testimonial__description{
    font-size: var(--small-font-size);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* Swiper Class */
.swiper-pagination-bullet{
    background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active{
    background-color: var(--title-color) !important;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .testimonial__container{
        width: initial;
    }
    .testimonial__card{
        padding: 1.25rem 1.5rem;
    }
}
/* ==========================================================================
   CONTACT SECTION
========================================================================== */

.contact__container{
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
            column-gap: 6rem;
    padding-bottom: 3rem;
}

.contact__title{
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info{
    display: grid;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: 300px;
}

.contact__card{
    background-color: var(--container-color);
    border:1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
}

.contact__card-icon{
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data{
    font-size: var(--small-font-size);
}

.contact__card-title{
    font-weight: var(--font-medium);
}

.contact__card-data{
    display: block;
    margin-bottom: var(--mb-0-75);
}

.contact__button{
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
}

.contact__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}
.contact__button:hover .contact__button-icon{
    -webkit-transform: translate(0.25rem);
            transform: translate(0.25rem);
}

.contact__form{
    width: 360px;
}

.contact__form-div{
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--border-color);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact__form-tag{
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
}

.contact__form-area{
    height: 11rem;
}

.form-error {
    font-size: 1.4rem;
    color: #b22b27;
    padding: 40px 15px 13px 26px;
    font-size: var(--small-font-size);
  }

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .contact__container{
        grid-column-gap: 3rem;
        -webkit-column-gap: 3rem;
                column-gap: 3rem;
    }
}
/* For medium devices */
@media screen and (max-width: 768px) {  
    .contact__container{
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
        row-gap: 3rem;
    }
    .contact__info{
        justify-content: center;
    }
    .contact__form{
        margin: 0 auto;
    }
}
@media screen and (max-width: 576px) {  
    .contact__info{
        grid-template-columns: 1fr;
    }
    .contact__form{
        width: 100%;
    }
}
/* ==========================================================================
   FOOTER SECTION
========================================================================== */

.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer__container{
    padding:2rem 0 2rem;
}

.footer__title,
.footer__link{
    color: var(--title-color);
}

.footer__title{
    text-align: center;
    margin-bottom: var(--mb-2);
}

.footer__link:hover{
     color: var(--title-color-dark);
}

.footer__list{
    display: flex;
    justify-content: center;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer__social{
    display: flex;
    justify-content: center;
    grid-column-gap: 1.125rem;
    -webkit-column-gap: 1.125rem;
            column-gap: 1.125rem;
}

.footer__social-link{
    background-color: var(--title-color);
    color: var(--container-color);
    font-size: 1.25rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer__social-link:hover{
    background-color: var(--title-color-dark);
    color: var(--icon-color);
    font-size: 1.25rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer__copy{
    display: block;
    margin-top: 4.5rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--small-font-size);
}

.footer__copy a{
    color: var(--title-color-dark);
    font-weight: var(--font-semi-bold);
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .footer__social-link{
        padding: 0.25rem;
        border-radius: 0.25rem;
        font-size: 1rem;
    }   
}

@media screen and (max-width: 576px) {
    .footer__container {
        padding: 2rem 0 2rem;
    }

    .footer__list {
        display: block;
        justify-content: center;
        grid-column-gap: 1.5rem;
        -webkit-column-gap: 1.5rem;
                column-gap: 1.5rem;
        margin-bottom: var(--mb-2);
        text-align: center;
    }

    .footer__copy{
        margin-top: 3.5rem;
    }   
   
}
/* ==========================================================================
   SCROLL UP CSS
========================================================================== */

.scrollup{
    position: fixed;
    right: 2.5rem;
    bottom: -20%;    
    background-color: var(--button-color);    
    color: var(--icon-color);
    opacity: 0.8;
    padding: 0.0rem 0.4rem;
    border-radius: 0.4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
    border: 3px solid transparent;
}

.scrollup__icon{
    margin-top: 5px;
    font-size: 2rem;
}

.scrollup:hover{
    background-color: var(--button-hover-color);
    color: var(--icon-color);
    border: 3px solid var(--text-color);
}

/* Show Scroll */
.show-scroll{
    bottom: 3rem;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .scrollup{
        right: 1.5rem;
        padding: 0.0rem 0.4rem;
    }
    .scrollup__icon{
        font-size: 1.25rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .show-scroll{
        bottom: 4rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .scrollup{
        right: 1rem;
    }
}
/* ==========================================================================
   STAGES SECTION CSS
========================================================================== */

.stages__container{
    grid-template-columns: repeat(4, 180px);
    justify-content: space-between;
    grid-column-gap: 2.8rem;
    -webkit-column-gap: 2.8rem;
            column-gap: 2.8rem;    
}

.how-i-work-item {
    z-index: 1;
    text-align: center;
    position: relative;
    margin-bottom: 0px;
    margin-top: 60px;
}

.how-i-work-item svg {
    top: -22rem;
    right: -138px;
    width: 11.3rem;
    position: absolute;
    -webkit-transform: skewY(-9deg);
            transform: skewY(-9deg);
}

.how-i-work-item .number {
    left: 50%;
    top: -30px;
    text-align: center;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.how-i-work-item .number span {
    width: 80px;
    height: 80px;
    font-size: 32px;
    font-weight: 700;
    position: relative;
    line-height: 80px;
    border-radius: 50%;
    color: var(--button-TextColor);
    display: inline-block;
    font-family: "Poppins", sans-serif;
    background: var(--stage-color);
    transition: all 0.5s ease;
}

.how-i-work-item .number-border {
    left: 50%;
    top: 10px;
    z-index: -3;
    position: absolute;
    margin-left: 30px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.how-i-work-item .number-border:after,
.how-i-work-item .number-border:before {
    top: 0;
    content: "";
    position: absolute;
    border-radius: 50%;
    transition: all 0.5s ease;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 2px solid var(--stage-color);
}

.how-i-work-item .number-border:after {
    right: -20px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.how-i-work-item .number-border:before {
    right: -30px;
    width: 120px;
    height: 120px;
    line-height: 120px;
}

.how-i-work-item .img {
    margin-bottom: 50px;
    border-radius: 50%;
}

.how-i-work-item .img img {
    border-radius: 50%;
    /* width: 300px;
    height: 200px; */
    transition: all 0.5s ease;
    border: 20px solid var(--stage-color);
}

.how-i-work-item .text {
    padding: 22px;
    text-align: center;
    position: relative;
    border-radius: 80px;
    background: var(--stage-color);
    color: var(--button-TextColor);
    transition: all 0.5s ease;
}

.how-i-work-item .text:after {
    top: -58px;
    left: 50%;
    content: "";
    position: absolute;
    border: 30px solid;
    transition: all 0.5s ease;
    border-color: transparent transparent var(--stage-color) transparent;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    color: var(--button-TextColor);
}

.how-i-work-item .text h5 {
    color:  var(--stage-text-color);
}

.how-i-work-item:hover .number span {
    background:  var(--title-color-dark);
}

.how-i-work-item:hover .img img {
    border-color: var(--title-color-dark);
}

.how-i-work-item:hover .text {
    background: var(--title-color-dark);
}

.how-i-work-item:hover .text:after {
    border-color: transparent transparent var(--title-color-dark) transparent;
}

.how-i-work-item:hover .number-border:after,
.how-i-work-item:hover .number-border:before {
    border-color: var(--title-color-dark);
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .stages__container {
        grid-template-columns: repeat(4, 182px);
        justify-content: center;
        grid-column-gap: 0.8rem;
        -webkit-column-gap: 0.8rem;
                column-gap: 0.8rem;
    }
    .how-i-work-item .arrow-dashed-img {
        top: -82px;
        right: -62px;
        width: 60%;
        position: absolute;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) { 
    .stages__container {
        grid-template-columns: repeat(2, 192px);
        justify-content: space-evenly;
        grid-column-gap: 1.8rem;
        -webkit-column-gap: 1.8rem;
                column-gap: 1.8rem;
    }    
    .how-i-work-item svg {       
        display: none;
    }
    .how-i-work-item .img img{
        width: 200px;
    }
}

@media screen and (max-width: 576px) {  
    .stages__container {
        grid-template-columns: repeat(1, 340px);
        justify-content: center;
        grid-column-gap: 1.2rem;
        -webkit-column-gap: 1.2rem;
                column-gap: 1.2rem;
    }    
    .how-i-work-item .arrow-dashed-img {
      display: none;
    }
    .how-i-work-item .img img{
        width: 254px;
    }
    .how-i-work-item .text {
        padding: 24px;
    }
    .how-i-work-item .number span{
        width: 100px;
        height: 100px;
        line-height: 100px;
    }    
    .how-i-work-item svg {       
        display: none;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .stages__container {
        grid-template-columns: repeat(1, 240px);
        justify-content: center;
        grid-column-gap: 1.2rem;
        -webkit-column-gap: 1.2rem;
                column-gap: 1.2rem;
    }
   
    .how-i-work-item .img img{
        width: 200px;
    }
    .how-i-work-item .text h5 {
        color: var(--icon-color);
        font-size: 15px;
    }
    .how-i-work-item .text {
        padding: 14px;
    }
    .how-i-work-item .number span {
        width: 70px;
        height: 72px;
        font-size: 25px;
        font-weight: 700;
        position: relative;
        line-height: 74px;
        border-radius: 50%;
        color: var(--icon-color);
        display: inline-block;
        font-family: "Poppins", sans-serif;
        background: var(--stage-color);
        transition: all 0.5s ease;
    }
}
/* ==========================================================================
   CALL ACTION SECTION
========================================================================== */

.call-to-action__container{
    grid-template-columns: repeat(1, 100%);
    justify-content: center;
    grid-column-gap: 1.8rem;
    -webkit-column-gap: 1.8rem;
            column-gap: 1.8rem;
}
.call-to-action {
    display: flex;
    justify-content: space-around;
}

.call-to-action h6 {
    font-size: var(--h2-font-size);
    text-align: center;
    padding-top:var(--mb-1);
    font-weight: var(--font-semi-bold);
    letter-spacing: 1px;
    margin-right: 8rem;

}

.call-to-action .button {
    float: right;
    margin-left: 8rem;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .call-to-action h6 {
        font-size: 20px;       
        margin-right: 4rem;
    
    }
    .call-to-action .button {        
        margin-left: 4rem;
    }
}
/* For medium devices */
@media screen and (max-width: 768px) {  
    .call-to-action {
        display: flex;
        padding: 50px 0;    
        flex-direction: column;
        align-items: center;        
    }
    .call-to-action h6 {   
        margin-bottom: var(--mb-1-5);  
        margin-right: 0rem;
    
    }
    .call-to-action .button {        
        margin-left: 0rem;
    }
}
/* ==========================================================================
   COUNTER SECTION
========================================================================== */

.counters__container{
    grid-template-columns: repeat(4, 180px);
    justify-content: flex-end;
    grid-column-gap: 2.8rem;
    -webkit-column-gap: 2.8rem;
            column-gap: 2.8rem;    
}

.counters__container i {
    display: inline-block;
    font-size: 35px;
    margin-bottom: 20px;
    background-color: var(--title-color-dark);
    color: var(--container-color);
    position: relative;
    z-index: 1;
    height: 60px;
    width: 60px;
    text-align: right;
    padding-right: 12px;
    padding-top: 12px;
    border-radius: 0.2rem;
    overflow: hidden;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.counters__container .counters__content:hover i {
    background-color: var(--title-color-dark);
}

.counters__container i::after {
    position: absolute;
    content: "";
    top: -23px;
    right: -44px;
    height: 100%;
    width: 95px;
    background-color: var(--title-color);
    z-index: -1;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.counters__container .counters__content:hover i::after {
    top: 15px;
    right: -1px;
}

.counters__container span {
    display: block;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 3px;
}

.counters__container h4 {
    font-size: 18px;
    margin-bottom: 20px;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .counters__container{        
        grid-template-columns: repeat(4, 160px);       
        justify-content: center;
        padding-left: 6rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .counters__container{
        grid-template-columns: repeat(2, 1fr);        
        justify-content: center;
        padding-left: 10rem;
    }
}

@media screen and (max-width: 576px) {  
    .counters__container{
        grid-template-columns: repeat(2, 1fr);        
        justify-content: center;
        padding-left: 0rem;
        text-align: center;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .counters__container{
        grid-template-columns: repeat(2, 1fr);        
        justify-content: flex-end;
    }
}

/* For small devices */
@media screen and (max-width: 280px) {
    .counters__container{
        grid-template-columns: repeat(2, 1fr);        
        justify-content: center;
        text-align: center;
    }
}
/* ==========================================================================
   LANDING PAGE CSS
========================================================================== */

.landing__container{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.landing__hero{
    padding: 100px 0;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    text-align: center;
    justify-items: center;
    background-color: #000;
}

.landing__hero img{    
    height: 11rem;
    margin-top: -5rem;
    box-shadow: 0 0 192px #ce0b496e;
    border-radius: 100%;
    width: 11rem;
    padding-right: 6px;
    border: 8px #e2115224 solid;
}

.landing__hero h1{
    font-size: 3rem;
    color: #e4e4e4;
    margin-bottom: 1rem;
}

.landing__hero h2{
    color: #e4e4e4;
    margin-bottom: 1rem;
}

.landing_main_demo{
    text-align: center;
    margin-top: 4rem;
    color: #000;
}

.landing_main_demo h1{
    font-size: 3rem;
    color: #000;
    margin-bottom: 1rem;
}

.landing_main_demo h2{
    color: #000;
    margin-bottom: 1rem;
}

.demo {
    box-shadow: 0 0 20px rgb(0 0 0 / 30%);
    border: 3px solid #dedede;
    padding-top: 25px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 40px;
    margin: 0 0 30px;
    will-change: transform,box-shadow;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.demo::before {
    content: "\B7\B7\B7";
    text-align: left;
    top: 0px;
    left: 0px;
    width: 92%;
    height: 25px;
    line-height: 25px;
    color: rgb(214, 214, 214);
    font-size: 40px;
    letter-spacing: 0px;
    position: absolute;
    padding: 0px 15px;
    border-bottom: 1px solid rgb(228, 228, 228);
}

.card__wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    grid-gap: 3rem;
    gap: 3rem;
    margin: 3rem 0;
    text-align: center;   
}

.portfolio__box{
    height: 300px;
    width: 100%;
    background-size: cover;
    background-position: top;
    transition: ease-in-out 5s;
    /* box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e; */
    border-radius: 0px 0px 2px 2px;      
    cursor: pointer;    
    zoom: 1.9;

}

.card__wrapper h1{    
    margin: 0;
    color: #000;
    position: relative;
    z-index: 1;
    font-weight: 800;
    font-size: 20px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.card__wrapper h1:after {
    content: "";
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #e21152e3;
    position: absolute;
    top: -8px;
    left: -15px;
    z-index: -1;
}

.portfolio__box:hover{
    background-position: bottom;
}

.landing__footer{
    padding: 30px 0;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 1vh;
    text-align: center;
    justify-items: center;
    background-color: #000;
    color: #fff;
}

.landing__footer h5{
    color: #fff;
    margin-bottom: 1rem;
}

.landing__footer h4{
    color: #fff;
    text-transform: uppercase;
}

.landing__footer span{
    color: #e21152;
}

.landing__Other_demo{
    display: grid;
    height: 18rem;
    margin-top: -5rem;
    box-shadow: 0 0 192px #ce0b496e;
    border-radius: 100%;
    width: 18rem;
    padding-right: 6px;
    border: 8px #e2115224 solid;
    text-align: center;
    align-content: center;
    margin:8rem 0rem;
    margin-left: auto;
    margin-right: auto;
}

.landing__Other_demo h1{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    font-size: 38px;
    text-transform: uppercase;
}

.landing__footer span{
    cursor: pointer;
}
/*================ BREAKPOINT ================*/ 
/* For medium devices */
@media screen and (max-width: 576px) {  
    .landing__hero h1{
        font-size: 2rem;
    }
    .landing__hero h2{
        font-size: 0.8rem;
    }
    .card__wrapper{
        grid-template-columns: repeat(1, 0.9fr);
        justify-content: space-around;
    }
    .portfolio__box{
        zoom: 1.55;
    }
    .landing__footer{       
        margin-bottom: -50px;
    }    
    .landing__footer h5{
        font-size: 10px;
    }
    .landing__footer h4{
        font-size: 12px;
    }
    .landing_main_demo h1{
        font-size: 2rem;
    }
    .landing_main_demo img{
        width: 14rem;
    }
    .landing_main_demo h2{
        font-size: 1rem;
    }
   .landing_main_demo h3{
    font-size: 0.8rem;
   }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .landing__Other_demo{
        width: 16rem;
        height: 16rem;
    }
    .landing__Other_demo h1{
        font-size: 28px;
    }
}
