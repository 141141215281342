/*================ GOOGLE FONTS ================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/*================ VARIABLES CSS ================*/
:root{
--header-height:3rem; 

/* Font and typography */
--body-font:"Poppins", sans-serif;

/*.5rem = 8px | 1rem = 16px ...*/
--big-font-size: 3.3rem;
--m-font-size: 2.6rem;
--h1-font-size: 2.25rem;
--h2-font-size: 1.5rem;
--h3-font-size: 1.25rem;
--normal-font-size :1rem;
--small-font-size: 0.875rem;
--smaller-font-size: 0.813rem;
--tiny-font-size: 0.625rem;

/* Font Weight */
--font-normal:400;
--font-medium: 500;
--font-semi-bold:600;
--font-large:900;

/* Margins Bottom */
/*.5rem 8px | 1rem = 16px ...*/
--mb-0-25:0.25rem;
--mb-0-5:0.5rem;
--mb-0-75:0.75rem;
--mb-1:1rem;
--mb-1-5:1.5rem;
--mb-2:2rem;
--mb-2-5:2.5rem;
--mb-3:3rem;

/* Z Index */     
--z-tooltip:10;
--z-fixed:100;
--z-modal:1000;
}

/*================ Responsive typography ================*/ 
@media screen and(max-width:992px){
  :root{ 
    --big-font-size:2.75rem;
    --h1-font-size:1.5rem;
    --h2-font-size:1.25rem;
    --h3-font-size:1rem;
    --normal-font-size:0.938rem;
    --small-font-size:0.813rem;
    --smaller-font-size:0.75rem;
  }
}

/*================ BASE ================*/ 
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

.landing-theme{
  --hue:0;
  --sat:0%;
  --title-color:hsl(var(--hue),var(--sat),20%);
  --title-color-dark:hsl(var(--hue),var(--sat),0%);
  --text-color:hsl(var(--hue),var(--sat),46%);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:hsl(var(--hue),var(--sat),20%); 
  --border-color:#646464; 
  --button-color:#e21152; 
  --button-hover-color:#ce0b49; 
  --icon-color:#fff; 
  --icon-color-dark:#fff; 
}

/* Light Mode Color*/
 .light-theme{
  /* Color mode HSL(hue,saturation,lightness) */
  --hue:0;
  --sat:0%;
  --title-color:hsl(var(--hue),var(--sat),20%);
  --title-color-dark:hsl(var(--hue),var(--sat),0%);
  --text-color:hsl(var(--hue),var(--sat),46%);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:hsl(var(--hue),var(--sat),20%);
  --border-color:hsl(var(--hue),var(--sat),46%); 
  --button-color:hsl(var(--hue),var(--sat),20%); 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:rgb(164, 164, 164);
}

.light_blue-theme{
  --hue:0;
  --sat:0%;
  --title-color:rgb(46, 46, 46);
  --title-color-dark:#047afa;
  --text-color:rgb(45, 45, 45);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:rgb(30, 30, 30);
  --border-color:rgb(24, 24, 24); 
  --button-color:#007aff; 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:#007aff;
}

.light_yellow-theme{
  --hue:0;
  --sat:0%;
  --title-color:rgb(46, 46, 46);
  --title-color-dark:#ffb400;
  --text-color:rgb(45, 45, 45);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:rgb(30, 30, 30);
  --border-color:rgb(24, 24, 24); 
  --button-color:#ffb400; 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:#ffb400;
}

.light_orange-theme{
  --hue:0;
  --sat:0%;
  --title-color:rgb(46, 46, 46);
  --title-color-dark:#fb5b0f;
  --text-color:rgb(45, 45, 45);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:rgb(30, 30, 30);
  --border-color:rgb(24, 24, 24); 
  --button-color:#fb5b0f; 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:#fb5b0f;
}

.light_pink-theme{
  --hue:0;
  --sat:0%;
  --title-color:rgb(46, 46, 46);
  --title-color-dark:#e21152;
  --text-color:rgb(45, 45, 45);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color:#fff;
  --stage-color:rgb(30, 30, 30);
  --border-color:rgb(24, 24, 24); 
  --button-color:#e21152; 
  --button-hover-color:hsl(var(--hue),var(--sat),0%);
  --button-TextColor:#fff;
  --button-TextColor-dark:#fff;
  --icon-color:#fff;
  --icon-color-dark:#fff;
  --header-Border-color:#e21152;
}

/* Dark Mode Color*/
.dark-theme{
  --title-color:#dee1e2;
  --title-color-dark:#047afa;
  --text-color:#dee1e2;
  --body-color:#030303;
  --container-color:#111111; 
  --stage-color:#1b1b1b; 
  --border-color:#646464; 
  --button-color:#007aff; 
  --button-hover-color:#111111; 
  --icon-color:#dee1e2; 
  --icon-color-dark:#dee1e2; 
  --header-Border-color:#007aff;
}
  
.dark_yellow-theme{
  --title-color:#FFFFFF;
  --title-color-dark:#ffb400;
  --text-color:#FFFFFF;
  --body-color:#030303;
  --container-color:#111111; 
  --stage-color:#1b1b1b; 
  --border-color:#646464; 
  --button-color:#ffb400; 
  --button-hover-color:#111111; 
  --icon-color:#FFFFFF; 
  --icon-color-dark:#FFFFFF;
  --header-Border-color:#ffb400;
}

.dark_orange-theme{
  --title-color:#dee1e2;
  --title-color-dark:#fb5b0f;
  --text-color:#ffffff;
  --body-color:#030303;
  --container-color:#111111; 
  --stage-color:#1b1b1b; 
  --border-color:#646464; 
  --button-color:#fb5b0f; 
  --button-hover-color:#111111; 
  --icon-color:#dee1e2; 
  --icon-color-dark:#dee1e2; 
  --header-Border-color:#fb5b0f;
}

.dark_pink-theme{
  --title-color:#dee1e2;
  --title-color-dark:#e21152;
  --text-color:#ffffff;
  --body-color:#030303;
  --container-color:#111111; 
  --stage-color:#1b1b1b; 
  --border-color:#646464; 
  --button-color:#e21152; 
  --button-hover-color:#111111; 
  --icon-color:#dee1e2; 
  --icon-color-dark:#dee1e2;
  --header-Border-color:#e21152;
}  

body{
  background-color: var(--body-color);
  color: var(--text-color);
}

/* ScrollBar Width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--container-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--title-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--title-color-dark);
}

h1, h2, h3{
color: var(--text-color);
font-weight: var(--font-semi-bold);
}

body,
button,
input,
textarea{
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}

/*================ REUSABLE CSS CLASSES ================*/ 
.section{
  padding:6rem 0 2rem;
}

.section__title{
  font-size:var(--h2-font-size);
  color:var(--title-color-dark);  
  margin-bottom:0.5rem;
  text-transform: uppercase;
}

.section__subtitle{
  display:block;
  font-size:var(--h1-font-size);
  font-weight: 900;
  margin-bottom:-1.5rem;
  text-transform: uppercase;
}

.section__zigzag{
  display:block;
  font-size:var(--h6-font-size);
  font-weight: 900;
  margin-bottom:2rem;
  text-transform: uppercase;
}

.section__title,
.section__subtitle,
.section__zigzag{
  text-align: center;
}

.section__mainTitle {
  font-size: 70px;
  text-transform: uppercase;
  -webkit-text-stroke: 2.8px var(--container-color);
  display:block;
  letter-spacing: 8px;
  text-align: center;
  margin-bottom: -4.1rem;
  color: var(--body-color);
  text-shadow: 2px 8px 6px var(--text-color),
                 0px -5px 35px rgba(255,255,255,0.3);
}

/*================ LAYOUT ================*/ 
.container{
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
}

.grid{
  display: grid;
  gap: 1.5rem;
}

/*================ BUTTONS ================*/
.button{
  display: inline-block;
  position:relative;
  background-color: var(--button-color);
  color: var(--icon-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-semi-bold);
  transition: color 1s;
  text-transform: uppercase;
  border: 3px solid transparent;
} 

.button:hover{
  background-color: var(--button-hover-color);
  color: var(--icon-color-dark);
  border: 3px solid var(--text-color);
} 

.button__icon{
  margin-left: var(--mb-0-5);
}

.button--flex{
  display:inline-flex;
  align-items: center;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
  .container{
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
  .button{
    padding: 1rem 1.75rem;
  }
  .button__icon{
    width: 22px;
    height: 22px;
  }
  .section__title {
    font-size: var(--h2-font-size);
    color: var(--title-color-dark);
    margin-bottom: 0.5rem;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }
  .section__mainTitle{
    display: none;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
  body{
    margin: 0 0 var(--header-height) 0;
  }
  .section {
      padding: 2rem 0 4rem;
  }
  .section__subtitle{
    margin-bottom: var(--mb-0-5);
  }
}
@media screen and (max-width: 576px) {  
  :root{
    --big-font-size:2.6rem;
  }
  .section__subtitle{
    font-size:var(--h2-font-size);
  }
}
/* For small devices */
@media screen and (max-width: 400px) {  
  :root{
    --big-font-size:2.25rem;
  }
  .container{
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }  
  .section__subtitle{
    font-size:var(--h6-font-size);
  }
}