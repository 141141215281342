/* ==========================================================================
   HEADER SECTION CSS
========================================================================== */

.header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav{
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo,
.nav__toggle{
    color: var(--title-color);
    font-weight: var(--font-medium);    
    font-size: 30px;
    cursor: pointer;
}

.nav__menu{
    margin-right: -4.8rem;
    margin-bottom: -5px;
}

.nav__list{
    display: flex;
    column-gap: 2rem;
}

.nav__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--normal-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    border-bottom: 3px solid transparent;
    transition: .3s;
}

.nav__icon,
.nav__close,
.nav__toggle{
    display: none;
}

/* Active Link */
.active,
.nav__link:hover {
    color: var(--title-color-dark);
    border-bottom: 3px solid var(--title-color-dark);
    padding-bottom: 1px;    
    cursor: pointer;
}

/* Change Background Header */
.scroll-header{
    box-shadow: 0 -4px 8px var(--header-Border-color);
    margin-top: -3px;
    padding-top: 3px;
    background-color: var(--container-color);
}

.HeaderBtn{
    padding: 0.8rem 1.8rem 0.8rem 1.8rem ;
    border-radius: 0.8rem;
    cursor: pointer;
}

a.button.HeaderBtn.active{
    /* background-color: var(--title-color-dark);    */
    color: var(--button-TextColor);
}

.nav__close{
    background-color: var(--body-color);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    box-shadow: 0px 0px 36px var(--text-color), 0px -5px 35px rgba(255,255,255,0.3);
    margin-bottom: 15px;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .nav__logo, .nav__toggle {
        font-size: 24px;
    }

    .nav__list {
        display: flex;
        column-gap: 1rem;
    }

    .nav__link{
        font-size: var(--small-font-size);
        border: none;
    }

    .HeaderBtn {
        padding: 0.8rem 1.6rem 0.8rem 1.6rem;
        border-radius: 0.8rem;
        font-size: 12px;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .header{
        top: initial;
        bottom: -1px;
        padding: 0.4rem 0rem 0.3rem 0rem;
        background-color: var(--container-color);
    }
    .scroll-header{
        box-shadow: 0 -2px 180px var(--header-Border-color);
    }
    .nav{
        height: var(--header-height);
    }    
    .nav__menu{
        position:fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--container-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }    
    .HeaderBtn{
        display: none;
    }
    .active-link, .nav__link:hover {
        color: var(--title-color-dark);
        border-bottom: 3px solid transparent;
        padding-bottom: 1px;
        border: none;
    }
    .VLine{
        display: none;
    }        
    /* Show Menu*/
    .show-menu{
        bottom: 0px;
    }
    .nav__list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
    .nav__icon{
        font-size: 1.2rem;
    }
    .nav__close{
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
        box-shadow: 0px 0px 6px var(--text-color), 0px -5px 35px rgba(255,255,255,0.3);
    }
    .nav__close:hover{
        color: var(--title-color-dark);
    }
    .nav__toggle{
        font-size: 1.1rem;
        cursor: pointer;
    }
    .nav__icon,
    .nav__close,
    .nav__toggle{
        display: block;
    }
}

@media screen and (max-width: 576px) {  
    .nav__logo {
        font-size: 18px;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {     
    .nav__menu{
        padding: 2rem 0.25rem 4rem;
    }
    .nav__list{
        column-gap: 0;
    }
}