/* ==========================================================================
   COUNTER SECTION
========================================================================== */

.counters__container{
    grid-template-columns: repeat(4, 180px);
    justify-content: flex-end;
    column-gap: 2.8rem;    
}

.counters__container i {
    display: inline-block;
    font-size: 35px;
    margin-bottom: 20px;
    background-color: var(--title-color-dark);
    color: var(--container-color);
    position: relative;
    z-index: 1;
    height: 60px;
    width: 60px;
    text-align: right;
    padding-right: 12px;
    padding-top: 12px;
    border-radius: 0.2rem;
    overflow: hidden;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.counters__container .counters__content:hover i {
    background-color: var(--title-color-dark);
}

.counters__container i::after {
    position: absolute;
    content: "";
    top: -23px;
    right: -44px;
    height: 100%;
    width: 95px;
    background-color: var(--title-color);
    z-index: -1;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.counters__container .counters__content:hover i::after {
    top: 15px;
    right: -1px;
}

.counters__container span {
    display: block;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 3px;
}

.counters__container h4 {
    font-size: 18px;
    margin-bottom: 20px;
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
    .counters__container{        
        grid-template-columns: repeat(4, 160px);       
        justify-content: center;
        padding-left: 6rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .counters__container{
        grid-template-columns: repeat(2, 1fr);        
        justify-content: center;
        padding-left: 10rem;
    }
}

@media screen and (max-width: 576px) {  
    .counters__container{
        grid-template-columns: repeat(2, 1fr);        
        justify-content: center;
        padding-left: 0rem;
        text-align: center;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .counters__container{
        grid-template-columns: repeat(2, 1fr);        
        justify-content: flex-end;
    }
}

/* For small devices */
@media screen and (max-width: 280px) {
    .counters__container{
        grid-template-columns: repeat(2, 1fr);        
        justify-content: center;
        text-align: center;
    }
}