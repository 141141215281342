/* ==========================================================================
   HOME SECTION CSS
========================================================================== */

.home__container{
    row-gap: 7rem;
}

.home__content{
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 1.2rem;
    align-items: center;
}

.home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon{
    font-size: 1.5rem;
    color: var(--title-color);
}

.home__social-icon:hover{
    font-size: 1.5rem;
    color: var(--title-color-dark);
}

.VLine {
    border-left: 2.6px solid var(--title-color);
    height: 280px;
    position: absolute;
    margin-left: 0.7rem;
    margin-top: 210px;
}

.wave {
    animation-name: wave-animation; 
    animation-duration: 2.5s;       
    animation-iteration-count: infinite;  
    transform-origin: 70% 70%;       
    display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(14.0deg) }  
    20% { transform: rotate(-8.0deg) }
    30% { transform: rotate(14.0deg) }
    40% { transform: rotate(-4.0deg) }
    50% { transform: rotate(10.0deg) }
    60% { transform: rotate( 0.0deg) }  
    100% { transform: rotate( 0.0deg) }
}

.home__title{
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
    letter-spacing: 0.1rem;
}

.home__MainTitle{
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-0-25);
    letter-spacing: 0.2rem;
}

.home__hand{
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}

.home__subtitle{
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__subtitle::before{
    content: "";
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--title-color);
    left: 0;
    top: 1rem;
}

.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.home__img{
    background: url(../../assets/img/user/profile.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    animation: profile_animate 8s ease-in-out infinite 1s;
    transition: all 0.5s ease-in;
}

@keyframes profile_animate {
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.home__scroll{
    margin-left: 9.25rem;
}

.wheel{
    animation: scroll 2 ease infinite;
}

@keyframes scroll{
    0%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(3.75rem);
    }
    0%{
        transform: translateY(0);
    }
}

.home__scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow{
    font-size: 1.25rem;
    color:var(--title-color)
}

/*================ BREAKPOINT ================*/ 
/* For large devices */
@media screen and (max-width: 992px) {
   .home__content{
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
   }
   .home__hand{
        width: 26px;
        height: 26px;
   }
   .home__subtitle{
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
   }
   .home__subtitle::before{
        width: 42px;
        top: 0.8rem;
   }
   .home__description{
        max-width: initial;
        margin-bottom: var(--mb-2-5);
   }
   .home__img{
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
   }   
   .home__scroll{
    margin-left: 7.5rem;
   }
}

/* For medium devices */
@media screen and (max-width: 768px) {  
    .home__content{
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }
    .home__img{
        order:initial;
        justify-self: initial;
    }
    .home__data{
        grid-column: 1/3;
    }
    .home__img{
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 300px;
        height: 300px;
    }
    .home__scroll{
        display: none;
    }    
}

@media screen and (max-width: 576px) {  
    .home__img{
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 250px;
        height: 250px;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .home__img{
        width: 180px;
        height: 180px;
    }
    .home__hand{
        width: 22px;
        height: 22px;
    }
}

