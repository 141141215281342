/* ==========================================================================
   LANDING PAGE CSS
========================================================================== */

.landing__container{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.landing__hero{
    padding: 100px 0;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    text-align: center;
    justify-items: center;
    background-color: #000;
}

.landing__hero img{    
    height: 11rem;
    margin-top: -5rem;
    box-shadow: 0 0 192px #ce0b496e;
    border-radius: 100%;
    width: 11rem;
    padding-right: 6px;
    border: 8px #e2115224 solid;
}

.landing__hero h1{
    font-size: 3rem;
    color: #e4e4e4;
    margin-bottom: 1rem;
}

.landing__hero h2{
    color: #e4e4e4;
    margin-bottom: 1rem;
}

.landing_main_demo{
    text-align: center;
    margin-top: 4rem;
    color: #000;
}

.landing_main_demo h1{
    font-size: 3rem;
    color: #000;
    margin-bottom: 1rem;
}

.landing_main_demo h2{
    color: #000;
    margin-bottom: 1rem;
}

.demo {
    box-shadow: 0 0 20px rgb(0 0 0 / 30%);
    border: 3px solid #dedede;
    padding-top: 25px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 40px;
    margin: 0 0 30px;
    will-change: transform,box-shadow;
    -moz-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.demo::before {
    content: "···";
    text-align: left;
    top: 0px;
    left: 0px;
    width: 92%;
    height: 25px;
    line-height: 25px;
    color: rgb(214, 214, 214);
    font-size: 40px;
    letter-spacing: 0px;
    position: absolute;
    padding: 0px 15px;
    border-bottom: 1px solid rgb(228, 228, 228);
}

.card__wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 3rem;
    margin: 3rem 0;
    text-align: center;   
}

.portfolio__box{
    height: 300px;
    width: 100%;
    background-size: cover;
    background-position: top;
    transition: ease-in-out 5s;
    /* box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e; */
    border-radius: 0px 0px 2px 2px;      
    cursor: pointer;    
    zoom: 1.9;

}

.card__wrapper h1{    
    margin: 0;
    color: #000;
    position: relative;
    z-index: 1;
    font-weight: 800;
    font-size: 20px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.card__wrapper h1:after {
    content: "";
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #e21152e3;
    position: absolute;
    top: -8px;
    left: -15px;
    z-index: -1;
}

.portfolio__box:hover{
    background-position: bottom;
}

.landing__footer{
    padding: 30px 0;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 1vh;
    text-align: center;
    justify-items: center;
    background-color: #000;
    color: #fff;
}

.landing__footer h5{
    color: #fff;
    margin-bottom: 1rem;
}

.landing__footer h4{
    color: #fff;
    text-transform: uppercase;
}

.landing__footer span{
    color: #e21152;
}

.landing__Other_demo{
    display: grid;
    height: 18rem;
    margin-top: -5rem;
    box-shadow: 0 0 192px #ce0b496e;
    border-radius: 100%;
    width: 18rem;
    padding-right: 6px;
    border: 8px #e2115224 solid;
    text-align: center;
    align-content: center;
    margin:8rem 0rem;
    margin-left: auto;
    margin-right: auto;
}

.landing__Other_demo h1{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    font-size: 38px;
    text-transform: uppercase;
}

.landing__footer span{
    cursor: pointer;
}
/*================ BREAKPOINT ================*/ 
/* For medium devices */
@media screen and (max-width: 576px) {  
    .landing__hero h1{
        font-size: 2rem;
    }
    .landing__hero h2{
        font-size: 0.8rem;
    }
    .card__wrapper{
        grid-template-columns: repeat(1, 0.9fr);
        justify-content: space-around;
    }
    .portfolio__box{
        zoom: 1.55;
    }
    .landing__footer{       
        margin-bottom: -50px;
    }    
    .landing__footer h5{
        font-size: 10px;
    }
    .landing__footer h4{
        font-size: 12px;
    }
    .landing_main_demo h1{
        font-size: 2rem;
    }
    .landing_main_demo img{
        width: 14rem;
    }
    .landing_main_demo h2{
        font-size: 1rem;
    }
   .landing_main_demo h3{
    font-size: 0.8rem;
   }
}

/* For small devices */
@media screen and (max-width: 350px) {  
    .landing__Other_demo{
        width: 16rem;
        height: 16rem;
    }
    .landing__Other_demo h1{
        font-size: 28px;
    }
}